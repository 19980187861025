import React, { Component } from 'react';
import {Button,TextField,Dialog,DialogActions,DialogContent,DialogTitle,FormControl, InputLabel, Select, MenuItem, ListItemText, Avatar, IconButton,Box} from '@mui/material';
import { connect } from 'react-redux';
import { setVisibleDialogProfile, setMsg, setLoading, setLogOut } from '../../../Actions/Actions.js';
import { MenuProps } from '../Utils/Utilis.js';

class FormDialog extends Component {

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        this.setState({ firstname: user.firstname });
        this.setState({ lastname: user.lastname });
        this.setState({ email: user.email });
        this.setState({ userRoles: user.roles });
        this.setState({ userId: user.id });
    };

    state = {
        userRoles: [],
        firstname: '',
        lastname: '',
        email: '',
        file: null,
        userId: 0
    };
    setFirstName(data) {
        this.setState({ firstname: data });
    }
    setLastname(data) {
        this.setState({ lastname: data });
    }
    setEmail(data) {
        this.setState({ email: data });
    }

    setUserRoles(event) {
        const { target: { value } } = event;
        this.setState({ userRoles: typeof value === 'string' ? value.split(',') : value });
    }

    render() {
        const { userRoles, email, firstname, lastname, file,userId } = this.state;
        const { handleClose, handleSave, open, rolesList,handleFile } = this.props;
        
        return (
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-create-folder" fullWidth={true} maxWidth={'sm'}>
                <form>
                    <DialogTitle id="form-dialog-create-folder">Mon profile</DialogTitle>
                    <DialogContent>
                        <label htmlFor="contained-button-file" justify="center" justifyContent= "center">
                            <IconButton>
                                <Avatar {...stringAvatar(`${lastname} ${firstname}`)} />
                            </IconButton>
                        </label>
                        <TextField fullWidth margin="dense" label="Nom de l'utilisateur" onChange={e => this.setLastname(e.target.value)} type="text" value={lastname} />
                        <TextField fullWidth margin="dense" label="Prénom de l'utilisateur" onChange={e => this.setFirstName(e.target.value)} type="text" value={firstname}/>
                        <TextField fullWidth margin="dense" label="Email" type="text" onChange={e => this.setEmail(e.target.value)} value={email}/>
                        <Box sx={{ m: 1 }}></Box>
                        <InputLabel id="demo-multiple-checkbox-label" sx={{mt:2,mb:2}}> Mon rôle</InputLabel>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={userRoles}
                                onChange={e => this.setUserRoles(e)}
                                renderValue={(selected) => {
                                    return selected.map((track) => track.name).join(", ")
                                }}
                                MenuProps={MenuProps}
                            >
                                {rolesList.map((role) => (
                                    <MenuItem key={role.id} value={role}>
                                        <ListItemText primary={role.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" type="button">
                            Fermer
                        </Button>
                        <Button color="primary" type="submit" onClick={e => handleSave(e, userRoles, firstname, lastname, email, file,userId)}>
                            Mettre à jour
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.visibleDialogProfile,
        rolesList: state.rolesList,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

        handleClose: event => {
            dispatch(setVisibleDialogProfile(false));
        },
        handleFile: event => {
            const files = event.currentTarget.form.querySelector('input[type=file]').files;
        },
        handleSave: (event, list, firstname, lastname, email, password,userId) => {
            dispatch(setLoading(true));
            dispatch(setVisibleDialogProfile(false));
            event.preventDefault();
            const files = event.currentTarget.form.querySelector('input[type=file]').files;
            var formData = new FormData();
            formData.append('avatar', files[0]);
            formData.append('firstname', firstname);
            formData.append('lastname',lastname);
            const token = localStorage.getItem('access_token');

            fetch(`http://localhost:3001/v1/users/${userId}`, {
                method: 'PATCH',
                body: formData, 
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => {
                dispatch(setLoading(false));
                dispatch(setMsg("Utilisateur modifié avec succès !"))
            }).catch(error => {
                dispatch(setLoading(false));
                dispatch(setMsg(error.response.data.message))
                if (error.response.data.code === 401) {
                    dispatch(setLogOut());
                }
                // your error handling goes here
            })

        }
    };
};

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: "#00787d",
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormDialog);
