import React, { Component } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import {TextField,CircularProgress, Link, Box, Grid } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux';
import axios from 'axios';
import { host } from '../../Api/config';
import { setLoading, setMsg, setToken, refreshFileList, initSubList,setSpace } from '../../Actions/Actions.js';
import DynamicSnackbar from '../Notification/DynamicSnackbar';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
      SELYS-AFRICA
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
      primary: {
          500: '#00787d'
      },
  },
  typography: {
      useNextVariants: true,
  }
});

class LoginThree extends Component {

  state = {
    email: '',
    password: ''
  };

  setEmail(data) {
    this.setState({ email: data });
  }
  
  setPassword(data) {
    this.setState({ password: data });
  }

render() {
  const { email, password } = this.state;
    const { handleSubmit, loading } = this.props;
  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Se connecter
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={e => this.setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => this.setPassword(e.target.value)}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                    <Grid container justify="center" justifyContent= "center">
                    {loading ?
                      <CircularProgress color="primary" /> :
                      null
                    }
                    </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={(e) => handleSubmit(e, email, password)}
            >
              Se connecter
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                Mot de passe oublié?
                </Link>
              </Grid>
              <Grid item>
                {/* <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    <DynamicSnackbar/>
    </div>
  );
}

}

const mapStateToProps = (state) => {
  return {
    open: state.visibleDialogUploadFile,
    loading: state.loading
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleSubmit: (event, email, password) => {
      event.preventDefault();
      dispatch(setLoading(true));
      axios.post(`${host}/auth/login`, {
        "email": email,
        "password": password
      })
        .then(res => {
          localStorage.setItem('access_token', res.data.tokens.access.token);
          localStorage.setItem('refresh_token', res.data.tokens.refresh.token);
          localStorage.setItem('user', JSON.stringify(res.data.user))
          dispatch(setToken(res.data.tokens.access.token))
          dispatch(setLoading(false));
          dispatch(initSubList());
          dispatch(refreshFileList());
          dispatch(setSpace("myspace"))
          window.location.reload(false);
        }).catch(error => {
          dispatch(setLoading(false));
          dispatch(setMsg(error.response.data.message));
          // your error handling goes here
        })

    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginThree);