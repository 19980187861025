import React, { Component } from 'react';
import { connect } from 'react-redux';
import File from '../File/File.jsx'; 
import FileListEmptyMessage from './FileListEmptyMessage';
import Loader from '../Loader/Loader.jsx'; 
import './FileList.css';
import {getRolesList,setMsg,getUsersList } from '../../Actions/Actions.js';

class FileList extends Component {

    componentDidMount() {
        this.props.rolesList();
        this.props.usersList();
      }

    render() {
        const { fileList, loading } = this.props;
        
        const fileListComponent = fileList.map((file, key) => {
            return <File id= {file.id} type={file.type} name={file.name} editable={file.editable} size={file.size} key={key} ext={file.ext} location ={file.location} path= {file.path}/>
        });

        return <div className="FileList">
            { loading ? 
                <Loader /> : 
                fileListComponent.length ? fileListComponent : <FileListEmptyMessage />
            }
        </div>
    }
}


const mapStateToProps = (state) => {
    const filteredList = state.fileList.filter(
        file => state.fileListFilter ? file.name.toLocaleLowerCase().match(state.fileListFilter.toLocaleLowerCase()) : true
    );
    return {
        fileList: filteredList,
        loading: state.loading
    };
};

//edwige.laubet@pharmaciedelame.net
//
const mapDispatchToProps = (dispatch) => {
    return {
        rolesList: () => {
            dispatch(getRolesList());
        },
        usersList: () => {
            dispatch(getUsersList());
        },
        errorHandle: (err) => {
            dispatch(setMsg(err))
        },
        handleClick: (event) => {
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileList);


