import React from 'react';
import {MenuItem,Typography,ListItemIcon} from '@mui/material';
import { connect } from 'react-redux';
import { setVisibleDialogCreateRole } from '../../../Actions/Actions.js';
import BallotIcon from '@mui/icons-material/Ballot';

function CreateRoleAction(props) {
    const {handleClick, handleClose} = props;

    const handleCloseAfter = (callback) => (event) => {
        callback();
        handleClose();
    };

    return (
        <MenuItem onClick={handleCloseAfter(handleClick)}>
            <ListItemIcon>
                <BallotIcon />
            </ListItemIcon>
            <Typography variant="inherit">
                Nouveau Rôle
            </Typography>
        </MenuItem>        
    );
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleClick: (event) => {
            dispatch(setVisibleDialogCreateRole(true));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRoleAction);
