import React from 'react';
import { Menu,IconButton} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { connect } from 'react-redux';
import CreateFolderAction from '../ContextMenu/ContextMenuActions/CreateFolderAction.jsx';
import UploadFileAction from '../ContextMenu/ContextMenuActions/UploadFileAction.jsx';
import CreateRoleAction from '../ContextMenu/ContextMenuActions/CreateRoleAction.jsx';
import CreateUserAction from '../ContextMenu/ContextMenuActions/CreateUserAction.jsx';
import ProfileAction from '../ContextMenu/ContextMenuActions/ProfileAction.jsx';
import LogoutAction from '../ContextMenu/ContextMenuActions/Logout.jsx';
import {setThreeDotsMenuVisible,setThreeDotsMenuPosition } from '../../Actions/Actions.js';

class ThreeDotsMenu extends React.Component {

  render() {
    const { visible, x, y } = this.props;
    return (
      <div style={{marginLeft:'1em'}}>
        <IconButton color="inherit" 
          aria-label="More"
          aria-haspopup="true"
          onClick={this.props.handleClick}>
          <MoreVertIcon />
        </IconButton>

        <Menu 
        anchorPosition={{ top: y, left: x }}
        anchorReference="anchorPosition"
        open={visible} 
        onClose={this.props.handleClose}
        >

          <CreateFolderAction handleClose={this.props.handleClose} />
          <UploadFileAction handleClose={this.props.handleClose} />
          <CreateUserAction handleClose={this.props.handleClose} />
          <CreateRoleAction handleClose={this.props.handleClose} />
          <ProfileAction handleClose={this.props.handleClose} />
          <LogoutAction handleClose={this.props.handleClose} />
        </Menu>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
    return {
      x: state.threeDotsMenuPosition[0] || 0,
      y: state.threeDotsMenuPosition[1] || 0,
      visible: !!state.threeDotsMenuVisible,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      handleClick: (event) => {
        event.preventDefault();
        event.stopPropagation();

            const x = event.clientX || (event.touches && event.touches[0].pageX);
            const y = event.clientY || (event.touches && event.touches[0].pageY);

            
            dispatch(setThreeDotsMenuVisible(true));
            dispatch(setThreeDotsMenuPosition(x, y));
      },
      handleClose: () => {
        dispatch(setThreeDotsMenuVisible(false));
      }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThreeDotsMenu);
