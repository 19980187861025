import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, InputBase, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { connect } from 'react-redux';

import ThreeDotsMenu from './ThreeDotsMenu.jsx';
import SettingMenu from './SettingMenu.jsx';
import { setFileListFilter } from '../../Actions/Actions.js'

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: '4.3em'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'block', // was none
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
      display: 'block'
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 100,
      '&:focus': {
        width: 200,
      },
    },
  },
});

function SearchAppBar(props) {
  const { classes, user } = props;
  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            {user.company.name}
          </Typography>
          {/* <MainMenu />  La liste de Mes dossiers, partager avec moi, pour tous */}
          <div className={classes.grow} />

          <div className="search">
            <div className="search--input" >
              <SearchIcon fontSize="small" style={{ color: `#919191` }} />
              <InputBase
                placeholder="Rechercher"
                value={props.value}
                onChange={props.handleChange}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          </div>
          <SettingMenu />
          <ThreeDotsMenu />
          {user.email}
        </Toolbar>
      </AppBar>
    </div>
  );
}

SearchAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
  return {
    value: state.fileListFilter || '',
    path: state.path,
    canGoBack: state.path.length,
    user: JSON.parse(localStorage.getItem('user'))
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (event) => {
      dispatch(setFileListFilter(event.currentTarget.value));
  },
  };
};


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SearchAppBar));
