import React, { Component } from 'react';
import FileList from './Components/FileList/FileList.jsx';
import Navbar from './Components/Navbar/Navbar.jsx';
import ContextMenu from './Components/ContextMenu/ContextMenu.jsx';
import Dialogs from './Components/Dialogs/Dialogs.jsx';
import LoginThree from './Components/Auth/LoginThree.jsx';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { setThreeDotsMenuVisible,setContextMenuVisible,setThreeDotsMenuPosition,refreshFileList, setFileListFilter, enterToPreviousDirectoryByIndex, enterToPreviousDirectory,threeMyspaceList } from './Actions/Actions.js';
import DynamicSnackbar from './Components/Notification/DynamicSnackbar.jsx';
import ControlledTreeView from './Components/Three/Three.jsx';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SettingPage from './Components/Settings/SettingPage.jsx';
import BreadcrumbText from './Components/Breadcrumb/BreadcrumbText.jsx';

import './App.css';

const theme = createTheme({
    palette: {
        primary: {
            500: '#00787d'
        },
    },
    typography: {
        useNextVariants: true,
    }
});

class App extends Component {

    componentDidMount() {
        if (this.props.token) {
            this.props.init();
        }
    };

    render() {
        const { token, path, handleClickPath, handleGoBack, canGoBack, user, space,handleClick} = this.props;
        if (!token) {

            return (<LoginThree />)
        }
        return (
            <ThemeProvider theme={theme}>
                <div className="wrapper" onClick={this.props.handleClick}>
                    <div className="app-window">
                        <div className="sideBar">
                            <header>
                                <Navbar />
                            </header>

                            {/* <div className="breadText">
                                <Typography variant="h6" color="inherit" noWrap>
                                    <BreadcrumbText
                                        path={path}
                                        handleClickPath={handleClickPath}
                                        handleGoBack={handleGoBack}
                                        canGoBack={canGoBack}
                                        //rootTitle={`${user.companyId}/users/${user.id}`}
                                        rootMySpace={`${user.companyId}/users/${user.id}`}
                                        space={space}
                                        color="black"
                                    />
                                </Typography>
                            </div> */}

                            <div className="chatList">
                                <ControlledTreeView />
                            </div>
                        </div>

                        <div className='contentArea' onContextMenu={this.props.handleHideContextMenu}>
                            <div className="breadText">
                                <Typography variant="h6" color="inherit" noWrap>
                                    <BreadcrumbText
                                        path={path}
                                        handleClickPath={handleClickPath}
                                        handleGoBack={handleGoBack}
                                        canGoBack={canGoBack}
                                        //rootTitle={`${user.companyId}/users/${user.id}`}
                                        rootMySpace={`${user.companyId}/users/${user.id}`}
                                        space={space}
                                        color="black"
                                    />
                                </Typography>
                            </div>
                            <FileList />
                        </div>

                        <ContextMenu />
                        <DynamicSnackbar />
                        <Dialogs />

                        <BrowserRouter>
                            <Switch>
                                <Route exact path="/setting" component={SettingPage} />
                            </Switch>
                        </BrowserRouter>
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        value: state.fileListFilter || '',
        path: state.path,
        canGoBack: state.path.length,
        user: JSON.parse(localStorage.getItem('user')),
        space: state.space
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
            dispatch(refreshFileList());
        },

        handleHideContextMenu: (event) => {
            if (!(event.target.tagName === 'INPUT' || /label/i.test(event.target.className))) {
                event.preventDefault();
            }
            const x = event.clientX || (event.touches && event.touches[0].pageX);
            const y = event.clientY || (event.touches && event.touches[0].pageY);
            dispatch(setThreeDotsMenuVisible(true));
            dispatch(setThreeDotsMenuPosition(x, y));
        },
        handleChange: (event) => {
            dispatch(setFileListFilter(event.currentTarget.value));
        },
        handleGoBack: (event) => {
            dispatch(enterToPreviousDirectory());
        },
        /**
         * @param {Object} event
         * @param {Number} index
         * @param {Array} path
         * @returns {undefined}
         */
        handleClickPath: (event, index) => {
            dispatch(enterToPreviousDirectoryByIndex(index));
            event.preventDefault();
        },
        /**
         * @param {Object} event
         * @returns {undefined}
         */
        handleClick: (event) => {
            event.stopPropagation();
            dispatch(setContextMenuVisible(false));
            dispatch(setThreeDotsMenuVisible(false));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
