//const host = 'http://localhost:9002/v1';
const host = 'https://api.ged2.selys.app/v1';

/**--primary-color: #00787d;
  --secondary-color: #40e0d0; */
export default {
    url_list: `${host}/ressources`,
    url_create_folder: `${host}/ressources/dir/create`,
    url_threeMySpace: `${host}/ressources/threeMyspace`,
    url_get_content: `${host}/ressources/file/download`,
    url_download: `${host}/ressources/file/download`,
    url_upload: `${host}/ressources/items/upload`,
    url_remove: `${host}/ressources/items/remove`,
    url_rename: `${host}/ressources/item/rename`,
    url_move: `${host}/ressources/items/move`,
    url_copy: `${host}/ressources/items/copy`,
    url_edit: `${host}/ressources/file/edit`,
    url_compress: `${host}/ressources/items/compress`,
    url_extract: `${host}/ressources/file/extract`,
    url_login: `${host}/auth/login`,
    url_permissions: `${host}/permissions`,
    url_roles: `${host}/roles`,
    url_users: `${host}/users`,

    isEditableFilePattern: /\.(txt|diff?|patch|svg|asc|cnf|cfg|conf|html?|cfm|cgi|aspx?|ini|pl|py|md|css|cs|jsx?|jsp|log|htaccess|htpasswd|gitignore|gitattributes|env|json|atom|eml|rss|markdown|sql|xml|xslt?|sh|rb|as|bat|cmd|cob|for|ftn|frm|frx|inc|lisp|scm|coffee|php[3-6]?|java|c|cbl|go|h|scala|vb|tmpl|lock|go|yml|yaml|tsv|lst)$/i,
    isImageFilePattern: /\.(jpe?g|gif|bmp|jfif|png|svg|mp4|tiff?)$/i,
    isExtractableFilePattern: /\.(gz|tar|rar|g?zip)$/i,

    actions: {
        create_folder: true,
        move: true,
        copy: true,
        copy_folder: true,
        compress: true,
        extract: true,
        edit: true,
        remove: true,
        upload: true,
        upload_by_chunks: true,
        preview_images: true,
    }
};
