import * as APIHandler from '../Api/ApiHandler.js';
import { apiClient } from '../Api/config.js';

/**
 * Request API to get file list for the selected path then refresh UI
 * @returns {Function}
 */
export const uploadFiles = (fileList) => (dispatch, getState) => {
    // Get user data in local storage
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);

    // Get path main and space main at state
    const { path,space } = getState();

    let fullPath = [...path];

    dispatch(setLoading(true));
    dispatch(setSelectedFiles([]));
    dispatch(setFileUploadProgress(50));

    APIHandler.uploadFiles(fullPath.join('/'),fileList,space).then(r => {
        dispatch(setFileUploadProgress(100));
        setTimeout(f => {
            dispatch(resetFileUploader());
        }, 300);
        dispatch(refreshFileList());
    }).catch(error => {
        dispatch(setMsg(error))
        if (error.response && error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        dispatch(setLoading(false));
    });
};

/**
 * Request API to rename file then dispatch defined events
 * @param {number} id
 * @param {String} newFileName
 * @returns {Function}
 */
export const renameItem = (id, newFileName) => (dispatch, getState) => {
    const userString = localStorage.getItem('user');
    const { space } = getState();
    
    dispatch(setLoading(true));
    APIHandler.renameItem(id, newFileName,space).then(blob => {
        dispatch(setVisibleDialogRename(false));
        dispatch(setLoading(false));
        dispatch(refreshFileList());
    }).catch(error => {
        console.log("eeror upload",error)
        dispatch(setMsg(error))
        if(error.response.data.message){
            dispatch(setMsg(error.response.data.message))
        }else{
            dispatch(setMsg(error.response.data.errorMsg))  
        }
        if (error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        dispatch(setLoading(false));
    });
};

/**
 * Request API to get download file then dispatch defined events
 * @param {String} fileName
 * @param {String} location
 * @returns {Function}
 */
export const downloadFile = (location,fileName) => (dispatch, getState) => {
    
    const { path,space } = getState();

    let fullPath = [...path];

    dispatch(setLoading(true));
    APIHandler.getFileBody(fullPath.join('/'), fileName,space).then(blob => {
        // TODO workaround large files disables ui for long time
        const blobUrl = window.URL.createObjectURL(blob);
        let tempLink = window.document.createElement('a');
        tempLink.href = blobUrl;
        tempLink.setAttribute('download', fileName);
        tempLink.click();
        window.URL.revokeObjectURL(blobUrl);
        dispatch(setLoading(false));
    }).catch(error => {
        dispatch(setMsg("Impossible de télécharger ce fichier"))  
        
        if (error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        dispatch(setLoading(false));
       /* dispatch({
            type: 'SET_ERROR_MSG',
            value: r
        });
        dispatch(setLoading(false));*/
    });
};

/**
 * Request API to create a folder then dispatch defined events
 * @param {String} createFolderName
 * @returns {Function}
 */
export const createNewFolder = (createFolderName) => (dispatch, getState) => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    const { path,space } = getState();
    let fullPath = [...path];
    dispatch(setLoading(true));

    APIHandler.createFolder(space,createFolderName,fullPath.join('/'),0,Number(user.id)).then(r => {
        dispatch(setVisibleDialogCreateFolder(false));
        dispatch(setLoading(false));
        dispatch(refreshFileList());
    }).catch(error => {
        if(error.response.data.message){
            dispatch(setMsg(error.response.data.message))
        }else{
            dispatch(setMsg(error.response.data.errorMsg))  
        }
        if (error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        dispatch(setLoading(false));
    });
};

/**
 * Request API to fetch myspace file and folders list
 * @returns {Function}
 */
export const threeMyspaceList = () => (dispatch, getState) => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);

    //dispatch(setLoading(true));

    APIHandler.getThree(user.id).then(r => {
        dispatch(setMySpaceFileList(r))
    }).catch(error => {
        if(error.response.data.message){
            dispatch(setMsg(error.response.data.message))
        }else{
            dispatch(setMsg(error.response.data.errorMsg))  
        }
        if (error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        //dispatch(setLoading(false));
    });
};

/**
 * Request API to remove an item then dispatch defined events
 * @param {Array} filenames
 * @returns {Function}
 */
export const removeItems = (files) => (dispatch, getState) => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);

    const { path,space } = getState();
    let fullPath = [...path];
    if(space){
        fullPath.unshift(user.id); 
    }
    //If i am in share user space
    if(!space && fullPath.length <= 1) {
        fullPath = ['',''];
    }

    dispatch(setLoading(true));
    APIHandler.removeItems(files, true,space).then(r => {
        dispatch(setLoading(false));
        dispatch(refreshFileList());
        dispatch(setVisibleDialogRemove(false));
    }).catch(error => {
        if(error.response.data.message){
            dispatch(setMsg(error.response.data.message))
        }else{
            dispatch(setMsg(error.response.data.errorMsg))  
        }
        if (error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        dispatch(setLoading(false));
    });
};


/**
 * Request API to move an item then dispatch defined events
 * @param {Array} filenames
 * @returns {Function}
 */
export const moveItems = (files) => (dispatch, getState) => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    const { path, pathSublist, selectedFolderSublist,space } = getState();
    let fullPath = [...path];
    let subFullPath = [...pathSublist];

    
    const destination  = subFullPath.join('/') + '/' + selectedFolderSublist.name ;

    console.log("DESTINATION MOVE",destination)
    

    dispatch(setLoading(true));
    APIHandler.moveItems(files,destination,space).then(r => {
        dispatch(setLoading(false));
        dispatch(setVisibleDialogMove(false));
        dispatch(refreshFileList());
    }).catch(error => {
        if(error.response.data.message){
            dispatch(setMsg(error.response.data.message))
        }else{
            dispatch(setMsg(error.response.data.errorMsg))  
        }
        if (error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        dispatch(setLoading(false));
    });
};


/**
 * Request API to copy an item then dispatch defined events
 * @param {Array} filenames
 * @returns {Function}
 */
export const copyItems = (files) => (dispatch, getState) => {
    const userString = localStorage.getItem('user');
const user = JSON.parse(userString);
    const { path, pathSublist, selectedFolderSublist,space } = getState();
    let fullPath = [...path];
    let subFullPath = [...pathSublist];
    if(space){
        fullPath.unshift(user.id); 
        subFullPath.unshift(user.id); 
    }
    //If i am in share user space
    if(!space && fullPath.length <= 1) {
        fullPath = ['',''];
    }
    const destination = subFullPath.join('/') + '/' + selectedFolderSublist.name;
    const filenames = files.map(f => f.name);

    dispatch(setLoading(true));
    APIHandler.copyItems(fullPath.join('/'), destination, filenames,space).then(r => {
        dispatch(setLoading(false));
        dispatch(setVisibleDialogCopy(false));
        dispatch(refreshFileList());
    }).catch(error => {
        if(error.response.data.message){
            dispatch(setMsg(error.response.data.message))
        }else{
            dispatch(setMsg(error.response.data.errorMsg))  
        }
        if (error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        dispatch(setLoading(false));
    });
};

/**
 * Request API to get file list for the selected path then refresh UI
 * @returns {Function}
 */
export const refreshFileList = () => (dispatch, getState) => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);// Get user to cache
    const { path,space } = getState(); // Get user path and Owrn variable value
    let fullPath = [...path];

    if(space == 'myspace' && fullPath.length == 0){
        if(user !== null){
            fullPath.unshift(`${user.companyId}/users/${user.id}`)
            dispatch(setPath(fullPath));
        }else {
            fullPath.unshift(0); 
        }
    }
    dispatch(threeMyspaceList())
    dispatch(setLoading(true));
    dispatch(setSelectedFiles([]));

    APIHandler.getFileList(fullPath.join('/'),space).then(r => {
        dispatch(setLoading(false));
        dispatch(setFileList(r));
    }).catch(error => {
        dispatch(setFileList([]));
        if(error.response.data.message){
            dispatch(setMsg(error.response.data.message))
        }else{
            dispatch(setMsg(error.response.data.errorMsg))  
        }
        if (error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        dispatch(setLoading(false));
    });
};


/**
 * Request API to get file list for the selected path then refresh UI
 * @returns {Function}
 */
export const refreshFileListSublist = () => (dispatch, getState) => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    const { pathSublist,space } = getState();
    let fullPath = [...pathSublist];
    
    dispatch(setLoadingSublist(true));
    dispatch(setSelectedFolderSublist(null));

    APIHandler.getFileList(fullPath.join('/'),space).then(r => {
        dispatch(setLoadingSublist(false));
        dispatch(setFileListSublist(r));
    }).catch(error => {
        dispatch(setFileListSublist([]));
        if(error.response.data.message){
            dispatch(setMsg(error.response.data.message))
        }else{
            dispatch(setMsg(error.response.data.errorMsg))  
        }
        if (error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        dispatch(setLoadingSublist(false));
    });
};


/**
 * Request API to get file content then dispatch defined events
 * @param {String} fileName
 * @returns {Function}
 */
export const getFileContent = (fileName) => (dispatch, getState) => {
    const { path,space } = getState();
    let fullPath = [...path];
   
    dispatch(setLoading(true));
    dispatch(setFileContent(null));
    dispatch(setVisibleDialogContent(true));
    APIHandler.getFileBody(fullPath.join('/'), fileName,space).then(blob => {
        dispatch(setFileContent(blob));
        dispatch(setLoading(false));
    }).catch(error => {
        if(error.response.data.message){
            dispatch(setMsg(error.response.data.message))
        }else{
            dispatch(setMsg(error.response.data.errorMsg))  
        }
        if (error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        dispatch(setLoading(false));
    });
};

/**
 * Request API to get file content then dispatch defined events
 * @param {String} fileName
 * @returns {Function}
 */
export const getFileContentForEdit = (fileName) => (dispatch, getState) => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    const { path,space } = getState();
    let fullPath = [...path];
    if(space){
        fullPath.unshift(user.id); 
    }
    dispatch(setLoading(true));
    dispatch(setFileContent(null));
    dispatch(setVisibleDialogEdit(true));
    APIHandler.getFileBody(fullPath.join('/'), fileName,space).then(blob => {
        dispatch(setFileContent(blob));
        dispatch(setLoading(false));
    }).catch(error => {
        if(error.response.data.message){
            dispatch(setMsg(error.response.data.message))
        }else{
            dispatch(setMsg(error.response.data.errorMsg))  
        }
        if (error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        dispatch(setLoading(false));
    });
};

/*export const getPermissionsList = () => (dispatch, getState) => {
    apiClient().get('/permissions').then(res => {
        dispatch(setPermissionsList(res.datas));
    }).catch(error => {
        setMsg(error)
        // your error handling goes here

    });
}*/

export const getRolesList = () => (dispatch, getState) => {
    apiClient().get('/roles').then(res => {
        dispatch(setRolesList(res.data.datas));
    }).catch(error => {
        setMsg(error)
        // your error handling goes here
    });
}

export const getUsersList = () => (dispatch, getState) => {
    apiClient().get('/users').then(res => {
        dispatch(setUsersList(res.data.datas));
    }).catch(error => {
        setMsg(error)
        // your error handling goes here
    });
}

export const shareToRole = (type, roles, name,path) => (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setVisibleDialogShare(false));
    apiClient().post('/share/to/role', {
        path: path,
        isFile: type === 'dir' ? false : true,
        roles: roles
    }).then(res => {
        dispatch(setLoading(false));
        dispatch(setMsg(type === 'dir' ? `Le dossier "${name}" est partagé` : `Le fichier "${name}" est partagé`))
    }).catch(error => {
        dispatch(setLoading(false));
        dispatch(setMsg(error.response.data.message))
        if (error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        // your error handling goes here
    })
}

export const shareToUser = (payload,name,type) => (dispatch, getState) => {
    const { path } = getState();
    dispatch(setLoading(true));
    dispatch(setVisibleDialogShare(false));
    apiClient().post('/shares/to-user',payload).then(res => {
        dispatch(setLoading(false));
        dispatch(setMsg(type === 'dir' ? `Le dossier "${name}" est partagé` : `du fichier "${name}" est partagé`))
    }).catch(error => {
        dispatch(setLoading(false));
        dispatch(setMsg(error.response.data.message))
        if (error.response.data.code === 401) {
            dispatch(setLogOut());
        }
        // your error handling goes here
    })
}

/**
 * This handles multiple selection by using shift key
 * @param {Object} lastFile
 * @returns {Function}
 */
export const setSelectedFileFromLastTo = (lastFile) => (dispatch, getState) => {
    const { fileList, selectedFiles } = getState();

    const lastPreviouslySelected = [...selectedFiles].pop();
    const lastPreviouslySelectedIndex = fileList.indexOf(fileList.find(f => f.name === lastPreviouslySelected.name))
    const lastSelectedIndex = fileList.indexOf(fileList.find(f => f.name === lastFile.name))

    let toAdd = [];
    if (lastSelectedIndex > lastPreviouslySelectedIndex) {
        toAdd = fileList.filter((index, element) => {
            return fileList.indexOf(index) <= lastSelectedIndex && fileList.indexOf(index) >= lastPreviouslySelectedIndex
        });
    } else {
        toAdd = fileList.filter((index, element) => {
            return fileList.indexOf(index) >= lastSelectedIndex && fileList.indexOf(index) <= lastPreviouslySelectedIndex
        });
    }
    dispatch(setSelectedFiles([...selectedFiles, ...toAdd]));
};


/**
 * @returns {Function}
 */
export const initSubList = () => (dispatch, getState) => {
    const { path } = getState();
    dispatch(setSelectedFolderSublist(null));
    dispatch(setFileListSublist([]));
    dispatch(setPathSublist([...path]));
    dispatch(refreshFileListSublist());
};

export const resetFileUploader = () => (dispatch, getState) => {
    dispatch(setFileUploadProgress(0));
    dispatch(setVisibleDialogUploadFile(false));
    dispatch(setFileUploadList([]));
};

export const enterToPreviousDirectory = () => (dispatch, getState) => {
    const { path } = getState();
    dispatch(setPath(path.slice(0, -1)));
    dispatch(setFileListFilter(null));
    dispatch(refreshFileList());
};

export const enterToPreviousDirectoryByIndex = (index) => (dispatch, getState) => {
    const { path } = getState();
    const newPath = [...path].slice(0, ++index);
    dispatch(setPath(newPath));
    dispatch(refreshFileList());
    dispatch(setFileListFilter(null));
};

export const enterToPreviousDirectorySublist = () => (dispatch, getState) => {
    const { pathSublist } = getState();
    dispatch(setPathSublist(pathSublist.slice(0, -1)));
    dispatch(refreshFileListSublist());
};

export const setPath = (path) => {
    return {
        type: 'SET_PATH',
        value: path
    };
};

export const setPathSublist = (path) => {
    return {
        type: 'SET_PATH_SUB_LIST',
        value: path
    };
};

export const enterToDirectory = (directory,passUrl) => (dispatch, getState) => {
    const {space} = getState();
    dispatch({
        type: 'ENTER_TO_DIRECTORY',
        value: `${directory}`
    });
    dispatch(setFileListFilter(null));
    dispatch(refreshFileList());
};

export const enterToDirectorySublist = (directory,passUrl) => (dispatch, getState) => {
    const {space} = getState();
    dispatch({
        type: 'ENTER_TO_DIRECTORY_SUB_LIST',
        value: `${directory}`
    });
    dispatch(refreshFileListSublist());
};

export const setFileList = (fileList) => {
    return {
        type: 'SET_FILE_LIST',
        value: fileList
    };
};

export const setFileListSublist = (fileList) => {
    return {
        type: 'SET_FILE_LIST_SUB_LIST',
        value: fileList
    };
};

export const setSelectedFiles = (files) => {
    return {
        type: 'SET_SELECTED_FILES',
        value: files
    };
};

export const setToken = (token) => {
    return {
        type: 'SET_TOKEN',
        value: token
    };
};

export const setMsg = (error) => {
    return {
        type: 'SET_ERROR_MSG',
        value: error
    };
}

/*export const setPermissionsList = (permissions) => {
    return {
        type: 'SET_PERMSSIONS_LIST',
        value: permissions
    };
};*/

export const setLogOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    return {
        type: 'SET_TOKEN',
        value: null
    };
};
export const setRolesList = (roles) => {
    return {
        type: 'SET_ROLES_LIST',
        value: roles
    };
};
export const setUsersList = (roles) => {
    return {
        type: 'SET_USERS_LIST',
        value: roles
    };
};
export const setSelectedFolderSublist = (file) => {
    return {
        type: 'SET_SELECTED_FOLDER_SUB_LIST',
        value: file
    };
};

export const setFileListFilter = (search) => {
    return {
        type: 'SET_FILE_LIST_FILTER',
        value: search
    };
};

export const setContextMenuVisible = (visible) => {
    return {
        type: 'SET_CONTEXT_MENU_VISIBLE',
        value: !!visible
    };
};

export const setContextMenuPosition = (x, y) => {
    return {
        type: 'SET_CONTEXT_MENU_POSITION',
        value: [x, y]
    };
};

export const setContextMenuPositionElement = (element) => {
    return {
        type: 'SET_CONTEXT_MENU_POSITION_ELEMENT',
        value: element
    };
};


export const setThreeDotsMenuVisible = (visible) => {
    return {
        type: 'SET_THREEDOT_MENU_VISIBLE',
        value: !!visible
    };
};

export const setThreeDotsMenuPosition = (x, y) => {
    return {
        type: 'SET_THREEDOT_MENU_POSITION',
        value: [x, y]
    };
};

export const setThreeDotsMenuPositionElement = (element) => {
    return {
        type: 'SET_THREEDOT_MENU_POSITION_ELEMENT',
        value: element
    };
};

export const toggleSelectedFile = (file) => {
    return {
        type: 'TOGGLE_SELECTED_FILE',
        value: file
    };
};

export const rightClickOnFile = (file) => (dispatch, getState) => {
    const { selectedFiles } = getState();
    const isSelected = selectedFiles.indexOf(selectedFiles.find(f => f.name === file.name)) !== -1;

    !isSelected && dispatch(setSelectedFiles([file]));
};

export const setLoading = (value) => {
    return {
        type: 'SET_LOADING',
        value: value
    };
};

export const setLoadingSublist = (value) => {
    return {
        type: 'SET_LOADING_SUB_LIST',
        value: value
    };
};

export const setVisibleDialogCreateFolder = (visible) => {
    return {
        type: 'SET_VISIBLE_DIALOG_CREATE_FOLDER',
        value: !!visible
    };
};

export const setVisibleDialogCreateRole = (visible) => {
    return {
        type: 'SET_VISIBLE_DIALOG_CREATE_ROLE',
        value: !!visible
    };
};
export const setSpace = (visible) => {
    return {
        type: 'SET_SPACE',
        value: visible
    };
};

export const setVisibleDialogCreateUser = (visible) => {
    return {
        type: 'SET_VISIBLE_DIALOG_CREATE_USER',
        value: !!visible
    };
};
export const setVisibleDialogProfile = (visible) => {
    return {
        type: 'SET_VISIBLE_DIALOG_PROFILE',
        value: !!visible
    };
};
export const setVisibleDialogUploadFile = (visible) => {
    return {
        type: 'SET_VISIBLE_DIALOG_UPLOAD_FILE',
        value: !!visible
    };
};

export const setVisibleDialogRename = (visible) => {
    return {
        type: 'SET_VISIBLE_DIALOG_RENAME',
        value: !!visible
    };
};

export const setVisibleDialogDetail = (visible) => {
    return {
        type: 'SET_VISIBLE_DIALOG_DETAIL',
        value: !!visible
    };
};

export const setVisibleDialogMove = (visible) => {
    return {
        type: 'SET_VISIBLE_DIALOG_MOVE',
        value: !!visible
    };
};
export const setVisibleDialogShare = (visible) => {
    return {
        type: 'SET_VISIBLE_DIALOG_SHARE',
        value: !!visible
    };
};
export const setVisibleDialogRemove = (visible) => {
    return {
        type: 'SET_VISIBLE_DIALOG_REMOVE',
        value: !!visible
    };
};
export const setVisibleDialogCopy = (visible) => {
    return {
        type: 'SET_VISIBLE_DIALOG_COPY',
        value: !!visible
    };
};

export const setVisibleDialogContent = (visible) => {
    return {
        type: 'SET_VISIBLE_DIALOG_CONTENT',
        value: !!visible
    };
};

export const setVisibleDialogEdit = (visible) => {
    return {
        type: 'SET_VISIBLE_DIALOG_EDIT',
        value: !!visible
    };
};

export const setFileContent = (blob) => {
    return {
        type: 'SET_FILE_CONTENT',
        value: blob
    };
};

export const setFileUploadProgress = (percentage) => {
    return {
        type: 'SET_FILE_UPLOAD_PROGRESS',
        value: percentage
    };
};

export const setFileUploadList = (files) => {
    return {
        type: 'SET_FILE_UPLOAD_LIST',
        value: files
    };
};

export const setMySpaceFileList = (files) => {
    return {
        type: 'SET_MYSPACE_FILE_LIST',
        value: files
    };
};