import React, { Component } from 'react';
import { connect } from 'react-redux';
import './FileListEmptyMessage.css';
import { Box } from '@mui/material';

class FileListEmptyMessage extends Component {
    render() {
        return (
            <div className="FileListEmptyMessage">
                <Box sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            direction: "column"
          }}>
                <Box
                    component="img"
                    sx={{
                        height: 233,
                        width: 350,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                        direction: "column"
                    }}
                    alt="The house from the offer."
                    src="../img/label.png"
                    
                />
                <div>
                    Dossier vide
                </div>
                </Box>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileListEmptyMessage);


