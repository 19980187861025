import React from 'react';
import { IconButton} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

function  SettingMenu () {
  const navigate = useHistory();
  

  const handleClick = () => {
    navigate.push("/setting");
  };

    return (
      <div style={{marginLeft:'1em'}}>
        <IconButton color="inherit" 
          aria-label="More"
          aria-haspopup="true"
          onClick={handleClick}>
          <SettingsIcon />
        </IconButton>
      </div>
    );

}


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingMenu);
