import React, { Component } from 'react';
import Box from '@mui/material/Box';
import {enterToDirectory,getFileContentForEdit,getFileContent} from '../../Actions/Actions.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { connect } from 'react-redux';
import config from '../../config.js';

const myspace = {
  id: 'root',
  name: 'Mes dossiers',
  location: 'myspace',
  children: [
   
  ],
};

const share = {
  id: 'root',
  name: 'Partager avec moi',
  location: 'share',
  children: [
    {
      id: '1',
      name: 'Administration',
    },
    {
      id: '3',
      name: 'Developpeurs',
      children: [
        {
          id: '4',
          name: 'Roodmap',
        },
      ],
    },
  ],
};

const common = {
  id: 'root',
  name: 'Pour tous',
  location: 'common',
  children: [
    {
      id: '1',
      name: 'Cahier de charges',
    },
    {
      id: '3',
      name: 'Clauses de confidentialités',
      children: [
        {
          id: '4',
          name: 'Contras',
        },
      ],
    },
  ],
};

class ControlledTreeView extends Component {

  render() {
    const { filePath } = this.props;
    const renderTree = (nodes) => (
      <TreeItem
       key={nodes.id}
       nodeId={nodes.id.toString()}
       label={nodes.name}
       onClick = {(event) => this.props.handleSelect(event, nodes,filePath)}
         >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    );
    const { threeMyspaceList } = this.props;
    myspace.children = threeMyspaceList

    return (
      <Box sx={{ minHeight: 110, flexGrow: 1, maxWidth: 300 }}>
      <TreeView
        aria-label="rich object"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={['root']}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ mb: 1 }}
      >
        {renderTree(myspace)}
      </TreeView>
      <TreeView
        aria-label="rich object"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={['root']}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ mb: 1 }}
      >
        {renderTree(share)}
      </TreeView>
      <TreeView
        aria-label="rich object"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={['root']}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {renderTree(common)}
      </TreeView>
    </Box>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    filePath: [...state.path],
    threeMyspaceList: state.threeMyspaceList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleSubmit: (event, email, password) => {
    
    },
     handleSelect: (event, ownProps,path) => {
      if (ownProps.type === 'file') {
        if (config.isEditableFilePattern.test(ownProps.name) || ownProps.editable) {
            dispatch(getFileContentForEdit(ownProps.name));
        } else if (config.isImageFilePattern.test(ownProps.name)) {
            dispatch(getFileContent(ownProps.name));
        }
        return;
    }
    
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlledTreeView);