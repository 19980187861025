import React, { Component } from 'react';
import {Button,Dialog,DialogActions,TextField,DialogContent,DialogTitle,Radio, RadioGroup, FormLabel, FormControlLabel, FormControl, Select, MenuItem, ListItemText, InputLabel,Box} from '@mui/material';
import { connect } from 'react-redux';
import { shareToRole, setVisibleDialogShare,shareToUser } from '../../../Actions/Actions.js';
import { MenuProps } from '../Utils/Utilis.js';

class FormDialog extends Component {

    state = {
        value: '',
        radioValue: 'role',
        userRoles: [],
        usersValue: [],
        permsValue: [],
        email: '',
    };

    componentWillReceiveProps(props) {
        this.setState({ value: props.realName });
    }

    handleRadioChange(event) {
        this.setState({ radioValue: event.target.value });
    }
    setEmail(data) {
        this.setState({ email: data });
      }
    setUserRoles(event) {
        const { target: { value } } = event;
        this.setState({ userRoles: typeof value === 'string' ? value.split(',') : value });
    }
    setUserValues(event) {
        const { target: { value } } = event;
        this.setState({ usersValue: typeof value === 'string' ? value.split(',') : value });
    }
    setPermsValues(event) {
        const { target: { value } } = event;
        this.setState({ permsValue: typeof value === 'string' ? value.split(',') : value });
    }
    render() {
        const { value, radioValue, userRoles,usersValue,permsValue,email } = this.state;
        const { handleClose, open, rolesList, usersList,permissionsList,type,path,handleSave,selectedFiles,currentUser} = this.props;

        return (
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-create-folder" fullWidth={true} maxWidth={'sm'}>
                <form>
                    <DialogTitle id="form-dialog-create-folder">Partage {type === 'dir' ? `du dossier "${value}" `: `du fichier "${value}" `}</DialogTitle>
                    <DialogContent>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Partager aux</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={radioValue}
                                onChange={e => this.handleRadioChange(e)}
                            >
                                <FormControlLabel value="role" control={<Radio />} label="Rôles" />
                                <FormControlLabel value="user" control={<Radio />} label="Utilisateurs" />
                                <FormControlLabel value="externe" control={<Radio />} label="Externe" />
                            </RadioGroup>
                        </FormControl>
                        {radioValue ==='role' ? <div>
                        <InputLabel id="multiple-roles-checkbox-label" sx={{ mt: 2 }}> Choisir les rôles</InputLabel>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <Select
                                labelId="multiple-roles-checkbox-label"
                                id="multiple-checkbox-roles"
                                multiple
                                value={userRoles}
                                onChange={e => this.setUserRoles(e)}
                                // input={<OutlinedInput label="roles" />}
                                renderValue={(selected) => {
                                    return selected.map((track) => track.name).join(", ")
                                }}
                                MenuProps={MenuProps}
                            >
                                {rolesList.map((role) => (
                                    <MenuItem key={role.id} value={role}>
                                        <ListItemText primary={role.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </div> : (radioValue == 'user' ? <div>
                        <InputLabel id="multiple-users-checkbox-label" sx={{ mt: 2 }}>Choisir les utilisateurs</InputLabel>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <Select
                                labelId="multiple-users-checkbox-label"
                                id="multiple-checkbox-users"
                                multiple
                                value={usersValue}
                                onChange={e => this.setUserValues(e)}
                                renderValue={(selected) => {
                                    return selected.map((track) => track.firstname).join(", ")
                                }}
                                MenuProps={MenuProps}
                            >
                                {usersList.map((user) => (
                                    <MenuItem key={user.id} value={user}>
                                        <ListItemText primary={`${user.firstname} ${user.lastname} (${user.email})`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box sx={{ m: 2 }}></Box>
                        <InputLabel id="multiple-permissions-checkbox-label">Accordez les permissions</InputLabel>
                        <FormControl fullWidth sx={{ mt:2 }}>
                            <Select
                            sx={{px:0 }}
                                labelId="multiple-permissions-checkbox-label"
                                id="multiple-checkbox-permissions"
                                multiple
                                value={permsValue}
                                onChange={e => this.setPermsValues(e)}
                                
                                renderValue={(selected) => {
                                    return selected.map((track) => track.name).join(", ")
                                }}
                                MenuProps={MenuProps}
                            >
                                {permissionsList.map((perm) => (
                                    <MenuItem key={perm.id} value={perm}>
                                        <ListItemText primary={` ${perm.name}`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </div> : <div>
                        <InputLabel id="multiple-users-checkbox-label" sx={{ mt: 2 }}>Entrer les emails séparé<script type="module" src=""></script> par une virgule ","</InputLabel>
                        <TextField  sx={{ mt: 2 }} fullWidth margin="dense" label="Email" type="text"onChange={e => this.setEmail(e.target.value)} value={email}  />
                        </div>) }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" type="button">
                            Fermer
                        </Button>
                        <Button color="primary" type="submit" onClick={e => handleSave(e,radioValue,value,path,type,userRoles,usersValue,permsValue,selectedFiles,currentUser)}>
                            Partager
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: JSON.parse(localStorage.getItem('user')),
        open: state.visibleDialogShare,
        realName: state.selectedFiles.length ? state.selectedFiles[0].name : '',
        type: state.selectedFiles.length ? state.selectedFiles[0].type : '',
        path: state.selectedFiles.length ? state.selectedFiles[0].path : '',
        rolesList: state.rolesList,
        usersList: state.usersList,
        selectedFiles: state.selectedFiles,
        permissionsList: state.permissionsList
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleClose: event => {
            dispatch(setVisibleDialogShare(false));
        },
        handleSave: (event,shareType,name,path,docType,roles,users,perms,selectedFiles,currentUser) => {
            event.preventDefault();
            console.log("SELECTED FILES ",selectedFiles)
            if(shareType === 'role'){
                dispatch(shareToRole(docType,roles,name,path))
            }else if(shareType === 'user'){
                let list = [];
                for(let i = 0; i< perms.length;i++){
                    list.push(perms[i].slug);
                }
                let payload = {
                    "ressourceId": selectedFiles[0].id,
                    "authorId": currentUser.id,
                    "targetUsers": users,
                    "roles": [],
                    "isPublic": false,
                    "anonymousEmails": [],
                    "permissions": list,
                }
               console.log("PAYLOD",payload)
                dispatch(shareToUser(payload,name,docType))
                

            }else {

            }
             }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDialog);
