import React, { Component } from 'react';
import Navbar from '../Navbar/Navbar'
import { createTheme, ThemeProvider  } from '@mui/material/styles';
import { connect } from 'react-redux';


const theme = createTheme({
    palette: {
        primary: {
            500: '#00787d'
        },
    },
    typography: {
        useNextVariants: true,
    }
});

class SettingPage extends Component {

    componentDidMount() {
        
    };

    render() {
        return (
            <div>
            <Navbar />
            
        </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
       
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
        },

        handleHideContextMenu: (event) => {
           
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);
