import React, { Component } from 'react';
import {Button,TextField,Dialog,DialogActions,DialogContent,DialogTitle,FormControl, InputLabel, Select, MenuItem, ListItemText,Box} from '@mui/material';
import { connect } from 'react-redux';
import {setVisibleDialogCreateRole,setMsg, setLoading,setLogOut,getRolesList} from '../../../Actions/Actions.js';
import { apiClient } from '../../../Api/config.js';

class FormDialog extends Component {

    componentDidMount() {
       
    };

    state = {
        rolePermissions: []
      };

      setRolePermissions(event) {
        const { target: { value } } = event;
        console.log("VALUE :",value)

        this.setState({rolePermissions: typeof value === 'string' ? value.split(',') : value});
    }

    render() {
        const { rolePermissions } = this.state;
        const { handleClose, handleSave, value, open,permissionsList} = this.props;
        const ITEM_HEIGHT = 48
        const ITEM_PADDING_TOP = 8
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250
                }
            }
        };
       
        return (
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-create-folder" fullWidth={true} maxWidth={'sm'}>
                <form>
                    <DialogTitle id="form-dialog-create-folder">Créer un rôle</DialogTitle>
                    <DialogContent>
                        <TextField autoFocus fullWidth margin="dense" label="Nom du role" type="text" value={value}/>
                        <Box sx={{ m: 2 }}></Box>
                        <InputLabel id="demo-multiple-checkbox-label" sx={{ mb: 1 }}>Accordez les permissions</InputLabel>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={rolePermissions}
                                onChange={e => this.setRolePermissions(e)}
                                renderValue={(selected) => {
                                   return selected.map((track) => track.name).join(", ")
                                }}
                                MenuProps={MenuProps}
                            >
                                {permissionsList.map((permission) => (
                                    <MenuItem key={permission.slug} value={permission}>
                                        <ListItemText primary={permission.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" type="button">
                            Fermer
                        </Button>
                        <Button color="primary" type="submit" onClick={e => handleSave(e,rolePermissions)}>
                            Créer
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.visibleDialogCreateRole,
        permissionsList: state.permissionsList
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        init: () => {
        },
        handleClose: event => {
            dispatch(setVisibleDialogCreateRole(false));
        },
        handleSave: (event,list) => {
            dispatch(setLoading(true));
            dispatch(setVisibleDialogCreateRole(false));
             event.preventDefault();
             const roleName = event.currentTarget.form.querySelector('input').value;
             var filterName = [];
            list.map((val)=> {
                filterName.push(val.name)
             });

             console.log("Result",filterName)
             apiClient().post('/roles',{
                name: roleName,
                slug: roleName.toString().toLowerCase(),
                permissions: filterName
           }).then(res => {
            dispatch(setLoading(false));
            dispatch(setMsg("Role créé avec succès !"))
            dispatch(getRolesList())          
          }).catch(error => { 
            dispatch(setLoading(false));
           dispatch(setMsg(error.response.data.message))
           if(error.response.data.code === 401){
            dispatch(setLogOut());
           }
            // your error handling goes here
        })
            
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDialog);
