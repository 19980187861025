import React from 'react';
import DialogContent from './Content/Content.jsx';
import DialogEdit from './Edit/Edit.jsx';
import DialogCreateFolder from './CreateFolder/CreateFolder.jsx';
import DialogRename from './Rename/Rename.jsx';
import DialogMove from './Move/Move.jsx';
import DialogCopy from './Copy/Copy.jsx';
import DialogUploadFile from './UploadFile/UploadFile.jsx';
import DialogCreateRole from './Role/Role.jsx';
import DialogRemove from './Remove/Remove.jsx';
import DialogCreateUser from './User/User.jsx';
import DialogProfile from './User/Profile.jsx';
import DialogShare from './Share/Share.jsx';
import DialogDetail from './Details/Detail.jsx';
function Dialogs(props) {
    return (
        <div className="Dialogs">
            <DialogContent />
            <DialogEdit />
            <DialogCreateFolder />
            <DialogMove />
            <DialogCopy />
            <DialogRename />
            <DialogUploadFile />
            <DialogCreateRole />
            <DialogRemove />
            <DialogCreateUser />
            <DialogProfile />
            <DialogShare />
            <DialogDetail />
        </div>
    );
}

export default Dialogs;
