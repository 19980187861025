import React, { Component } from 'react';
import {TextField,Button,Dialog,DialogActions,DialogContent,DialogTitle} from '@mui/material';
import { connect } from 'react-redux';
import { renameItem, setVisibleDialogDetail } from '../../../Actions/Actions.js';

class FormDialog extends Component {

    state = {
        value: ''
    };

    componentWillReceiveProps (props) {
        this.setState({value: props.realName});
    }

    handleChange (event) {
        this.setState({value: event.currentTarget.form.querySelector('input').value});
    }

    handleSave (event) {
        this.props.handleSave(event)(this.props.realName, this.state.value);
    }

    render() {
        const { value } = this.state;
        const { handleClose, open,type,size } = this.props;
        

        return (
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-create-folder" fullWidth={true} maxWidth={'sm'}>
                <form>
                  <DialogTitle id="form-dialog-create-folder">Détails</DialogTitle>
                  <DialogContent>
                    <TextField
                    inputProps={{
                        readOnly: true
                      }}
                     autoFocus fullWidth margin="dense" label={type === 'dir' ? "Nom du dossier" : "Nom du fichier"} type="text" value={value} />
                     <TextField
                    inputProps={{
                        readOnly: true
                      }}
                     autoFocus fullWidth margin="dense" label="Taille" type="text" value={size} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary" type="button">
                      Fermer
                    </Button>
                  </DialogActions>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.visibleDialogDetail,
        type: state.selectedFiles.length ? state.selectedFiles[0].type : '',
        size:  state.selectedFiles.length ? state.selectedFiles[0].size : 0,
        realName: state.selectedFiles.length ? state.selectedFiles[0].name : ''
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleClose: event => {
            dispatch(setVisibleDialogDetail(false));
        },
        handleSave: event => (realName, newName) => {
            event.preventDefault();
            dispatch(renameItem(realName, newName));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDialog);
