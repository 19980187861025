import React, { Component } from 'react';
import {Button,Dialog,DialogActions,DialogContent,DialogTitle} from '@mui/material';
import { connect } from 'react-redux';
import { setVisibleDialogRemove, setSelectedFolderSublist, enterToPreviousDirectorySublist, removeItems } from '../../../Actions/Actions.js';
import FileListSublist from '../../FileList/FileListSublist/FileListSublist.jsx'; 

class FormDialog extends Component {

    render() {
        const { 
            handleClose, handleRemove, open, 
            selectedFiles 
        } = this.props;

        return (
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-move" fullWidth={true} maxWidth={'sm'}>
                <form>
                    <DialogTitle id="form-dialog-move">
                        Voulez-vous vraiment supprimer? <small style={{color: 'grey'}}></small>
                    </DialogTitle>
                    <DialogContent>
                        <FileListSublist />
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} color="primary" type="button">
                            Fermer
                        </Button>
                        <Button color="primary" onClick={(e) => handleRemove(e, selectedFiles)} type="submit">
                            Supprimer
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    // prevent moving to same folder

    return {
        open: state.visibleDialogReMove,
        selectedFolderSublist: state.selectedFolderSublist,
        selectedPath: state.selectedFolderSublist ? [...state.pathSublist, state.selectedFolderSublist.name] : [],
        selectedFiles: state.selectedFiles,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleClose: (event) => {
            dispatch(setSelectedFolderSublist(null));
            dispatch(setVisibleDialogRemove(false));
        },
        handleRemove: (event, selectedFiles) => {
            dispatch(removeItems(selectedFiles));
        },
        handleGoBack: (event) => {
            dispatch(setSelectedFolderSublist(null));
            dispatch(enterToPreviousDirectorySublist());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDialog);
