import React, { Component } from 'react';
import {Button,TextField,Dialog,DialogActions,DialogContent,DialogTitle,FormControl, InputLabel, Select, MenuItem, ListItemText} from '@mui/material';
import { connect } from 'react-redux';
import {setVisibleDialogCreateUser,setMsg, setLoading,setLogOut,getUsersList} from '../../../Actions/Actions.js';
import { apiClient } from '../../../Api/config.js';

class FormDialog extends Component {

    componentDidMount() {
       
    };

    state = {
        userRoles: null,
        firstname: '',
        lastname: '',
        email: '',
        password: ''
      };
      setFirstName(data) {
        this.setState({ firstname: data });
      }
      setLastname(data) {
        this.setState({ lastname: data });
      }
      setEmail(data) {
        this.setState({ email: data });
      }
      setPassword(data) {
        this.setState({ password: data });
      }
      setUserRoles(event) {
        const { target: { value } } = event;
        this.setState({userRoles: typeof value === 'string' ? value.split(',') : value});
    }

    render() {
        const { userRoles,email,firstname,lastname,password } = this.state;
        const { handleClose, handleSave, open,rolesList,user} = this.props;
        const ITEM_HEIGHT = 48
        const ITEM_PADDING_TOP = 8
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250
                }
            }
        };
       
        return (
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-create-folder" fullWidth={true} maxWidth={'sm'}>
                <form>
                    <DialogTitle id="form-dialog-create-folder">Créer un utilisateur</DialogTitle>
                    <DialogContent>
                        <TextField autoFocus fullWidth margin="dense" label="Nom de l'utilisateur" onChange={e => this.setLastname(e.target.value)} type="text" value={lastname} />
                         <TextField fullWidth margin="dense" label="Prénom de l'utilisateur" onChange={e => this.setFirstName(e.target.value)} type="text" value={firstname} />
                         <TextField  fullWidth margin="dense" label="Email" type="text"onChange={e => this.setEmail(e.target.value)} value={email}  />
                         <TextField  fullWidth margin="dense" label="Mot de passe" onChange={e => this.setPassword(e.target.value)} type="text" value={password}  />
                         <InputLabel id="demo-multiple-checkbox-label" sx={{mb:2,mt:2}}>Attribuez un rôle</InputLabel>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                value={userRoles}
                                onChange={e => this.setUserRoles(e)}
                                
                                renderValue={(selected) => {
                                   return selected.name
                                }}
                                MenuProps={MenuProps}
                            >
                                {rolesList.map((role) => (
                                    <MenuItem key={role.id} value={role}>
                                        <ListItemText primary={role.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" type="button">
                            Fermer
                        </Button>
                        <Button color="primary" type="submit" onClick={e => handleSave(e,userRoles,firstname,lastname,email,password,user)}>
                            Créer
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.visibleDialogCreateUser,
        rolesList: state.rolesList,
        user: JSON.parse(localStorage.getItem('user')),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        
        handleClose: event => {
            dispatch(setVisibleDialogCreateUser(false));
        },
        handleSave: (event,list,firstname,lastname,email,password,user) => {
            dispatch(setLoading(true));
            dispatch(setVisibleDialogCreateUser(false));
             event.preventDefault();
             apiClient().post('/users',{
                firstname: firstname,
                lastname: lastname,
                email: email,
                password: password,
                roles: [list],
                companyId: user.companyId
           }).then(res => {
            dispatch(setLoading(false));
            dispatch(setMsg("Utilisateur créé avec succès !"))
            dispatch(getUsersList())
          }).catch(error => { 
            dispatch(setLoading(false));
           dispatch(setMsg(error.response.data.message))
           if(error.response.data.code === 401){
            dispatch(setLogOut());
           }
            // your error handling goes here
        })
            
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDialog);
