import React from 'react';
import PropTypes from 'prop-types';
import {CircularProgress,Grid} from '@mui/material';
import { withStyles } from '@mui/styles';
const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 10,
  },
});

function Loader(props) {
    const { classes } = props;
    return (
        <Grid container justify="center" justifyContent= "center">
            <CircularProgress className={classes.progress} />
        </Grid>
    );
}

Loader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loader);
