import axios from 'axios';
import config from './../config.js';
import { apiClient } from './config.js';

/**
 * Fetch API to list files from directory
 * @param {String} path
 * @returns {Object}
 */
export function list(path,space) {
    return apiClient().get(config.url_list + '?path=' + path,{
        params: {
            space: space
            }
    });
};


/**
 * Fetch API to create a directory
 * @param {String} space
 * @param {String} name
 * @param {String} location
 * @param {int} size
 * @param {String} type
 * @param {int} shareId
 * @param {int} userId
 * @returns {Object}
 */
export function createDirectory(space,name,location,shareId,userId) {
    return apiClient().post(config.url_create_folder,{
        space: space,
        name: name,
        location: location,
        size: 0,
        type: 'dir',
        shareId: shareId,
        userId:userId
    });
};

/**
 * @param {int} id
 * @returns {Object}
 */
export function threeMyspaceList(id) {
    return apiClient().get(`${config.url_threeMySpace}/${id}`);
};

/**
 * Fetch API to login
 * @param {String} email
 * @param {String} password
 * @returns {Object}
 */
export function loginApi(email, password) {
    return axios.post(config.url_login,{
        body: {
            "email": email,
            "password": password
        }
    });
};

/**
 * Fetch API to get file body
 * @param {String} path
 * @returns {Object}
 */
export function getFileContent(path,space) {
   // return fetch(config.url_get_content + '?path=' + (encodeURIComponent(path) || '/'));
   return apiClient().get(config.url_get_content + '?path=' + (encodeURIComponent(path) || '/'),
    {
        responseType: 'blob',
        params: {
            space: space
            }
    }
    );
};


/**
 * Fetch API to remove a file or folder
 * @param {Array} ressources
 * @param {Boolean} recursive
 * @param {String} space 
 * @returns {Object}
 */
export function remove(ressources, recursive = true,space) {
    return apiClient().post(config.url_remove, {
        ressources: ressources,
        space: space
    });
};

/**
 * Fetch API to move files
 * @param {Array} resources
 * @param {String} destination // New destination
 * @param {String} space
 * @returns {Object}
 */
export function move(ressources, destination,space) {
    return apiClient().post(config.url_move, {
        ressources: ressources,
        destination: destination,
        space: space,
    });
};

/**
 * Fetch API to move files
 * @param {number} id
 * @param {String} destination
 * @param {String} space
 * @returns {Object}
 */
export function rename(id, destination,space) {
    return apiClient().post(config.url_rename, {
        id: id,
        destination: destination,
        space: space
    });
};

/**
 * Fetch API to copy files
 * @param {String} path
 * @param {Array} filenames
 * @param {Boolean} recursive
 * @returns {Object}
 */
export function copy(path, destination, filenames,mySpace) {
    return apiClient().post(config.url_copy, {
        path: path,
        destination: destination,
        filenames: filenames,
        mySpace: mySpace
    });
};

/**
 * Fetch API to copy files
 * @param {String} location
 * @param {Object<FileList>} fileList
 * @returns {Object}
 */
export function upload(location, fileList,space,formData = new FormData()) {
    [...fileList].forEach(f => {
        formData.append('files[]', f);
    });
    formData.append('location', location);
    formData.append('space',space);
    const token = localStorage.getItem('access_token');

    return fetch(config.url_upload, {
        method: 'POST',
        body: formData, 
        headers: {
            'Authorization': `Bearer ${token}`,
            // a workaround for node connector, passing the path by header
            location: location
        }
    });
};
