import axios from "axios";
export const host = 'https://api.ged2.selys.app/v1';
//export const host = 'http://localhost:9002/v1';


const apiClient = () => {
    const axiosInstance = axios.create({
        baseURL: host,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json',
            'accept': 'application/json',
        }
    });

    // Add a request interceptor
    axiosInstance.interceptors.request.use(function (config) {
        // Do something before request is sent
        const token = localStorage.getItem('access_token');
       config.headers.Authorization =  token ? `Bearer ${token}` : '';
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use(
        response => response,
        error => {
            const originalRequest = error.config;
            // Prevent infinite loops
            if (error.response.status === 401 && originalRequest.url === '/auth/refresh-tokens') {
                localStorage.removeItem("access_token")
                return Promise.reject(error);
            }
            if (error.response.status === 401) {
                const refreshToken = localStorage.getItem('refresh_token');

                if (refreshToken) {

                    return axiosInstance
                        .post('/auth/refresh-tokens', { refreshToken: refreshToken })
                        .then((response) => {
                            localStorage.setItem('access_token', response.data.access);
                            localStorage.setItem('refresh_token', response.data.refresh);

                            axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
                            originalRequest.headers['Authorization'] = "Bearer " + response.data.access;

                            return axiosInstance(originalRequest);
                        })
                        .catch(err => {
                            return Promise.reject(err);
                        });

                } else {
                    return Promise.reject(error);
                }
            }
            // specific error handling done elsewhere
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};



export { apiClient };