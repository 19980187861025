import React from 'react';
import {MenuItem,Typography,ListItemIcon} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { connect } from 'react-redux';
import { setVisibleDialogProfile } from '../../../Actions/Actions.js';

function ProfileAction(props) {
    const {handleClick, handleClose} = props;

    const handleCloseAfter = (callback) => (event) => {
        callback();
        handleClose();
    };

    return (
        <MenuItem onClick={handleCloseAfter(handleClick)}>
            <ListItemIcon>
                <AccountCircleIcon />
            </ListItemIcon>
            <Typography variant="inherit">
               Mon profile
            </Typography>
        </MenuItem>        
    );
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleClick: (event) => {
            dispatch(setVisibleDialogProfile(true));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAction);
